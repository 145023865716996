<template>
  <!--  -->
  <Layout>
    <div class="main-note" v-loading.fullscreen="loading">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
        <div class="layout c-card">
          <!--  -->
          <div class="md-note-1" v-ani.fade-up>
            <div class="hd flex-center flex-between">
              <div class="c-title-2 s22">送货单</div>
              <div class="btn flex-center s16 color-blue round pointer" @click="exportNote">
                <img src="@/assets/images/icon-print.png" alt="">
                <span>导出打印</span>
              </div>
            </div>
            <div class="bd flex-middle s16 color-blue" >
              <div class="btn pointer round flex-center" v-for="(item,index) in sendLogList" :key="index" :class="{ on: current == 1 }" @click="toNoteDetail(item.send_sn)">送货单{{ index + 1 }}</div>
            </div>
          </div>
           <!-- 项目信息汇总 -->
          <div class="md-note-5" v-ani.fade-up>
            <div class="tit s18 color-2 font-medium">项目信息汇总</div>
            <div class="list s16 color-4 flex-wrap">
              <div class="li">
                <span class="t1 color-9">工程名称:</span>
                <span class="t2">{{ noteDetail?.order_purchase_info?.project_name || '无'}}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">业主单位:</span>
                <span class="t2">{{ noteDetail?.order_purchase_info?.owner_unit || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">总包单位:</span>
                <span class="t2">{{ noteDetail?.order_purchase_info?.winning_unit || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">分包单位:</span>
                <span class="t2">{{ noteDetail?.order_purchase_info?.shall_unit || '无' }}</span>
              </div>
            </div>
          </div>
          <!-- 采购信息汇总 -->
          <div class="md-note-6" v-ani.fade-up>
            <div class="tit s18 color-2 font-medium">采购信息汇总</div>
            <div class="list s16 color-4 flex-wrap">
              <div class="li">
                <span class="t1 color-9">收货人:</span>
                <span class="t2">{{ noteDetail?.baseInfo?.username || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">手机号码:</span>
                <span class="t2">{{ noteDetail?.baseInfo?.mobile || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">订单编号:</span>
                <span class="t2">{{ noteDetail?.baseInfo?.order_sn || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">采购商:</span>
                <span class="t2">{{ noteDetail?.baseInfo?.company_name || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">收货地址:</span>
                <span class="t2">{{ noteDetail?.baseInfo?.address || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">下单时间:</span>
                <span class="t2">{{ noteDetail?.baseInfo?.create_time || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">信誉等级:</span>
                <span class="t2" v-if="noteDetail?.baseInfo?.user_level?.score">易筑分{{ noteDetail?.baseInfo?.user_level?.score }}分</span>
              </div>
              <div class="li">
                <span class="t1 color-9">订单备注:</span>
                <span class="t2">{{ noteDetail?.baseInfo?.user_note || '无' }}</span>
              </div>
              <div class="li">
                <span class="t1 color-9">发货单号:</span>
                <span class="t2">{{ noteDetail?.send_sn || '无' }}</span>
              </div>
            </div>
          </div>
          <!-- 送货明细表 -->
          <div class="md-note-2" v-ani.fade-up>
            <div class="tit-1 s18 color-2 font-medium">送货明细表</div>
            <div class="tit-2 s16 color-2 font-medium">发货物流</div>
            <div class="s16 color-4 list">
              <!-- <div class="li li-inline li-split">
                <span class="t1">发货状态:</span> 
                <span class="t2"></span>
              </div> -->
              <div class="li li-inline li-split">
                <span class="t1">发货批号:</span> 
                <span class="t2">第{{ noteDetail?.order_shipping_times }}批发货</span>
              </div>
              <!-- <div class="li li-inline">
                <span class="t1">运费:</span> 
                <span class="t2">{{  }}</span>
              </div> -->
              <div class="li">
                <span class="t1">发货方式:</span> 
                <span class="t2">{{ noteDetail?.shipping_info?.shipping_type_name }}</span>
              </div>
              <div class="li">
                <span class="t1">发货地址:</span> 
                <span class="t2">{{ noteDetail?.shipping_info?.username }}{{ noteDetail?.shipping_info?.mobile}}{{ noteDetail?.shipping_info?.address }}</span>
              </div>
              <div class="li">
                <span class="t1">运单号:</span> 
                <span class="t2">{{ noteDetail?.shipping_info?.shipping_sn }}</span>
              </div>
              <div class="li">
                <span class="t1">发货时间:</span> 
                <span class="t2">{{ noteDetail?.send_goods[0]?.send_time }}</span>
              </div>
              <div class="li">
                <span class="t1">发货备注:</span> 
                <span class="t2">{{ noteDetail?.remark }}</span>
              </div>
            </div>
          </div>
          <!-- 行程轨迹： -->
          <div class="md-note-3" v-ani.fade-up v-if="shippingData?.logistics.length > 0">
            <div class="tit s16 color-2 font-medium">行程轨迹：</div>
            <div class="list s16 color-4">
              <div class="li" v-for="(item,index) in shippingData?.logistics" :key="index">
                <div class="time color-9">{{ item.time }}</div>
                <div class="text">{{ item.context }}</div>
              </div>
            </div>
          </div>
          <!-- 商品列表 -->
          <div class="md-note-4" v-ani.fade-up>
            <div class="table s16 color-4" :class="{ showAll: more }">
              <div class="thead color-9 flex-middle">
                <div class="td">商品</div>
                <div class="td">剩余收货数量</div>
                <div class="td">发货时间</div>
                <div class="td">货物状态</div>
                <!-- <div class="td">不合格原因</div> -->
              </div>
              <div class="tbody">
                <div class="tr flex" v-for="(item, index) in noteDetail?.send_goods" :key="index">
                  <div class="td">
                    <router-link class="goods flex-middle s16 color-4" to="">
                      <img :src="item.goods_img" alt="" class="goods-cover">
                      <div class="text">
                        <div class="text-item">
                          <span class="color-9">商品标题：</span>
                          <span>{{ item.goods_name }}</span>
                        </div>
                        <div class="text-item">
                          <span class="color-9">规格：</span>
                          <span>{{ item.goods_spec }}</span>
                        </div>
                      </div>
                      <div>x{{ item.goods_count }}</div>
                    </router-link>
                  </div>
                  <!-- 剩余收货数量 -->
                  <div class="td" style="color: red">{{ item.leave_count }}{{ item.goods_unit }}</div>
                  <!-- 发货时间 -->
                  <div class="td">{{ item.send_time }}</div>
                  <!-- 货物状态 -->
                  <div class="td">
                    <div>
                      <p class="btn-ope">已发货</p>
                      <!-- <p class="btn-ope underline" @click="dialog_prod_feedback = true">商品售后</p> -->
                    </div>
                  </div>
                  <!-- 不合格原因--售后才需要 -->
                  <!-- <div class="td">
                    <div class="reason">
                      <p>货物数量不对、货不对版</p>
                      <p>换货中，3件</p>
                      <p class="s14 color-red">货物数量不对、货不对版</p>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="more flex-center pointer s16 color-blue" @click="more = !more" v-if="list.length > 4">
              <img src="@/assets/images/icon-more.png" alt="">
              <span>{{ more ? '收起' : '展开' }}列表</span>
            </div>
            <div class="btn pointer round flex-center" @click="toOrderReceive" v-if="noteDetail.can_upload_img">确认收货</div>
          </div>
          <!-- 配送详情 -->
          <div class="md-note-7" v-ani.fade-up>
            <div class="s18 color-2 font-medium">配送详情</div>
            <div class="tips s14 color-red bg-red-light round flex-middle">
              <img src="@/assets/images/icon-info.png" alt="">
              <span>提示:建议在上传照片时，图片中需包含送货车牌号以及货物，以免双方造成不良好用户体验。上传图片后，请点击下方的确认提交按钮。</span>
            </div>
            <div class="list">
              <div class="li flex-top" :class="noteDetail?.driver_imgs.length > 0 ? 'active' : ''">
                <div class="text">
                  <div class="tit s16 color-4">装车图片</div>
                  <div class="desc s14 color-red">
                    <div class="t1">(供应商或送货司机上传拍照)</div>
                    <div class="t2 flex-middle">
                      <img src="@/assets/images/icon-info.png" alt="">
                      <span>需拍车牌号</span>
                    </div>
                  </div>
                </div>
                <div class="imgs flex-wrap">
                  <img v-for="(item,index) in noteDetail?.driver_imgs" :key="index" :src="item" alt="" class="round img">
                </div>
              </div>
              <div class="li flex-top" :class="noteDetail?.receive_imgs.length > 0 ? 'active' : ''">
                <div class="text">
                  <div class="tit s16 color-4">产品入场图片</div>
                  <div class="desc s14 color-red">
                    <div class="t1">(供应商或送货司机上传拍照)</div>
                  </div>
                </div>
                <div class="imgs flex-wrap">
                  <img v-for="(item,index) in noteDetail?.receive_imgs" :key="index" :src="item" alt="" class="round img">
                </div>
              </div>
              <div class="li flex-top" :class="noteDetail?.unload_imgs.length > 0 ? 'active' : ''">
                <div class="text">
                  <div class="tit s16 color-4">产品验证图</div>
                  <div class="desc s14 color-red">
                    <div class="t1">(买家或收货人上传)</div>
                    <!-- <div class="t2 flex-middle">
                      <img src="@/assets/images/icon-info.png" alt="">
                      <span>需拍照上传送货单</span>
                    </div> -->
                  </div>
                </div>
                <div class="imgs flex-wrap">
                  <div class="imgs flex-wrap">
                    <img v-for="(item,index) in noteDetail?.unload_imgs" :key="index" :src="item" alt="" class="round img">
                    
                    <!-- <div class="btn-camera flex-center round pointer">
                      <img src="@/assets/images/icon-camera.png" alt="" class="icon">
                      <input type="file" @change="handleFile">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 确认收货 -->
    <DialogOrderConfirm
      type="DeliveryNote"
      v-if='dialog_confirm'
      @close='dialog_confirm = false'
      @cancel='dialog_confirm = false'
      @confirm='receiveOrderConfirm'
      :send_sn="send_sn" 
    />
    <!-- 商品反馈 -->
    <DialogOrderProdFeedback
      v-if="dialog_prod_feedback"
      @close='dialog_prod_feedback = false'
      @cancel='dialog_prod_feedback = false'
      @confirm='dialog_prod_feedback = false'
    />
  </Layout>
</template>

<script>
import { SendLogListApi, SendLogDetailApi, SendLogShippingilApi, sendLogConfirmOrderApi, exportSendLogOrderApi } from "@/request/api/order.js";
export default {
  data() {
    return {
      routes: [
        { name: '首页', link: '/'},
        { name: '我的订单', link: '/order'},
        { name: '全部订单', link: '/order'},
        { name: '订单详情页', link: '' }
      ],
      current: 1,
      more: false,
      list: [1,2,3,4,5,6],
      imgs: [],
      dialog_confirm: false,
      dialog_prod_feedback: false,
      loading: false,

      order_id: "",
      send_sn: "",
      sendLogList: [],
      noteDetail: {
        send_goods:[],
        driver_imgs: [],
        receive_imgs: [],
        unload_imgs: []
      },
      shippingData: {
        logistics: []
      },//物流信息
    };
  },
  computed: {

  },
  watch: {
    $route(val) {
      this.current = this.$route.query.current
      this.getData()
    }
  },
  created() {
    this.current = this.$route.query.current
    this.getData()
  },
  mounted() {
    this.order_id = this.$route.query.order_id;
    this.getSendLogList();
  },
  methods: {
    handleFile(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        const el = e.target.files[i];
        let obj = {
          id: Math.floor(Math.random() * 10000),
          name: el.name,
          type: el.type,
          src: ''
        }
        let reader = new FileReader()
        reader.readAsDataURL(el)
        reader.addEventListener("load", () => {
          obj.src = reader.result
          this.imgs.push(obj)
        }, false)
      }
    },
    getData() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    //送货单列表
    getSendLogList(){
      SendLogListApi({order_id: this.order_id}).then(res => {
        if(res.status == 200){
          this.sendLogList = res.data.list;
          if(this.sendLogList.length > 0){
            this.send_sn = this.sendLogList[0].send_sn;
            this.getSendLogDetail();
            this.getSendLogShipping();
          }
        }
      })
    },
    //选择送货单
    toNoteDetail(send_sn){
      this.send_sn = send_sn;
      this.getSendLogDetail();
    },
    //获取送货单详情
    getSendLogDetail(){
      SendLogDetailApi({send_sn: this.send_sn}).then(res => {
        if(res.status == 200){
          this.noteDetail = res.data.info;
        }
      })
    },
    //物流信息
    getSendLogShipping(){
      SendLogShippingilApi({send_sn: this.send_sn}).then(res => {
        if(res.status == 200){
          this.shippingData = res.data;
        }
      })  
    },
    //确认收货
    toOrderReceive(){
      this.dialog_confirm = true;
    },
    receiveOrderConfirm(){
      let result = [];
      this.noteDetail.send_goods.forEach((item) => {
        console.log(item.receive_status);
        if(item.receive_status == 0){
          let obj = {
            id: item.id,
            result: "合格"
          }
          result.push(obj);
        }
      })
      let params = {
        send_sn: this.noteDetail.send_sn,
        result: JSON.stringify(result)
      }
      sendLogConfirmOrderApi(params).then(res => {
        if(res.status == 200){
          this.dialog_confirm = false;
          this.$message.success("已确认收货");
          this.getSendLogDetail();
          this.getSendLogShipping();
        }
      })
    },
    //导出送货单
    exportNote(){
      let params = {
        send_sn: this.send_sn,         
        type: 1,
        ftype: 0,
        order_id: this.order_id
      }
      exportSendLogOrderApi(params).then(res => {
        if(res.status == 200){
          window.location.href = res.data.file;
          ths.$message.success("导出成功")
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.main-note{
  padding-bottom: .4rem;
}
.layout{
  padding: .3rem .3rem .2rem;
}
.md-note-1{
  border-bottom: 1px solid #e6f2f8;
  padding-bottom: .4rem;
  .hd{
    .btn{
      min-width: 1.1rem;
      height: .4rem;
      background: #cce2fb;
      padding: .1rem;
      transition: .3s;
      img{
        width: .16rem; margin-right: .05rem;
      }
      &:hover{
        background-color: $blueLight;
      }
    }
  }
  .bd{
    margin-top: .31rem;
    .btn{
      min-width: 1.1rem;
      height: .4rem;
      padding: 0 .1rem;
      background: #cce2fb;
      margin-right: .1rem;
      transition: .3s;
      &:hover{
        opacity: .9;
      }
      &.on{
        background: $blue; color: #fff;
      }
    }
  }
}
.md-note-2{
  padding-top: .36rem;
  .tit-2{
    margin-top: .22rem;
    &:before{
      content: '';
      display: inline-block;
      width: 0.03rem;
      height: 0.15rem;
      background-color: $blue;
      border-radius: 0.015rem;
      margin-right: .05rem; margin-top: .02rem;
    }
  }
  .list{
    line-height: calc(30 / 16 * 1em);
    margin-top: .17rem;
    .li{
      margin-bottom: .13rem;
      &-inline{
        display: inline-block;
      }
      &-split{
        &:after{
          content: '';
          display: inline-block;
          width: 1px;
          height: 0.14rem;
          margin: 0 .4rem;
          background-color: #cae1ec;
        }
      }
    }
    .t1{
      color: #999;
      margin-right: .04rem;
    }
  }
}
.md-note-3{
  border-radius: 0.04rem;
  border: solid 0.01rem #e6f2f8;
  margin-top: .22rem;
  padding: .27rem .3rem .29rem;
  .list{
    margin-top: .32rem;
    .li{
      position: relative;
      padding-left: .26rem; padding-bottom: .34rem;
      min-height: .89rem;
      &:last-of-type{
        padding-bottom: 0;
        min-height: auto
      }
      &:not(:last-child):after{
        content: '';
        position: absolute; left: .07rem; top: .03rem; z-index: 1;
        width: 2px; height: 100%;
        margin: auto;
        background: #cce2fb;
      }
      &:before{
        content: '';
        position: absolute; left: 0; top: .03rem; z-index: 2;
        width: 0.16rem;
        height: 0.16rem;
        background-color: #ffffff;
        border-radius: 0.08rem;
        border: solid 0.02rem #cce2fb;
      }
      &:first-child{
        &:before{
          border-width: .05rem;
          border-color: $blue;
        }
      }
    }
    .text{
      line-height: calc(30 / 16 * 1em);
      margin-top: .04rem;
    }
  }
}
.md-note-4{
  .table{
    margin-top: .4rem;
    .thead{
      min-height: 0.5rem;
      background-color: #f4f9fd;
      border: solid 0.01rem #e6f2f8;
    }
    .td{
      text-align: center;
      &:first-child{
        width: 4.4rem;
        text-align: left;
        padding-left: .3rem;
      }
      &:nth-child(2){
        width: 2.2rem;
      }
      &:nth-child(3){
        width: 3.29rem;
      }
      &:nth-child(4){
        width: 2.2rem;
      }
      &:last-child{
        flex: 1;
      }
    }
    .tr{
      border: solid 0.01rem #e6f2f8;
      margin-top: .19rem;
      .td{
        border-right: solid 0.01rem #e6f2f8;
        padding: .3rem;
        display: flex; align-items: center; justify-content: center;
        &:first-child{
          justify-content: flex-start;
        }
        &:last-of-type{
          border-right: none;
        }
      }
      &:nth-child(4) ~ .tr{
        display: none;
      }
    }
    .btn{
      cursor: pointer;
      margin: .13rem 0;
    }
    .btn-2{
      text-decoration: underline;
    }
    &.showAll{
      .tr:nth-child(4) ~ .tr{
        display: flex;
      }
      &~.more{
        img{
          transform: rotate(180deg);
        }
      }
    }
    .btn-ope{
      margin: .12rem 0;
      cursor: pointer;
    }
    .reason{
      p{
        margin: .08rem 0;
      }
    }
  }
  .goods{
    width: 100%;
    &-cover{
      width: 1rem; height: 1rem;
      object-fit: cover;
      border-radius: .04rem;
      margin-right: .19rem;
    }
    .text{
      flex: 1;
      &-item{
        line-height: calc(30 / 16 * 1em);
        margin-bottom: .04rem;
      }
    }
    .tags{
      margin-top: .1rem;
      .tag{
        border: 1px solid;
        padding: .02rem .04rem;
        margin-right: .1rem;
      }
    }
  }
  .more{
    margin-top: .17rem;
    img{
      width: .14rem; margin-right: .04rem;
    }
  }
  .btn{
      
      width: 1.5rem;
      min-width: 1.1rem;
      height: .4rem;
      background: #cce2fb;
      color: #0174da;
      padding: .1rem;
      transition: .3s;
      &:hover{
        background-color: $blueLight;
      }
    }
}
.md-note-5{
  border-bottom: 1px solid #e6f2f8;
  padding: .35rem 0 .33rem;
  .list{
    margin-top: .17rem;
    line-height: calc(30 / 16 * 1em);
    .li{
      margin-right: .8rem;
      &:last-of-type{
        margin-right: 0;
      }
    }
    .t1{
      margin-right: .04rem;
    }
  }
}
.md-note-6{
  border-bottom: 1px solid #e6f2f8;
  padding: .36rem 0 .19rem;
  .list{
    margin-top: .18rem;
    line-height: calc(30 / 16 * 1em);
    .li{
      margin-bottom: .12rem;
      &:nth-of-type(3n+1){
        width: 2.58rem;
      }
      &:nth-of-type(3n+2){
        width: 4.62rem;
      }
      &:nth-of-type(3n+3){
        width: calc(100% - 2.58rem - 4.62rem);
      }
    }
    .t1{
      margin-right: .04rem;
    }
  }
}
.md-note-7{
  margin-top: .37rem;
  .tips{
    margin-top: .25rem;
    padding: .21rem .2rem;
    img{
      width: .14rem; margin-right: .07rem;
    }
    span{
      flex: 1;
    }
  }
  .list{
    margin-top: .39rem;
    .li{
      position: relative;
      padding-left: .34rem; padding-bottom: .1rem;
      min-height: 1.17rem;
      &:last-of-type{
        padding-bottom: 0;
        min-height: auto
      }
      &:not(:last-child):after{
        content: '';
        position: absolute; left: .07rem; top: .03rem; z-index: 1;
        width: 2px; height: 100%;
        margin: auto;
        background: #cce2fb;
      }
      &:before{
        content: '';
        position: absolute; left: 0; top: .03rem; z-index: 2;
        width: 0.16rem; height: 0.16rem;
        background-color: #ffffff;
        border-radius: 0.08rem;
        border: solid 0.02rem #cce2fb;
      }
    }
    .active{
      &:before{
        border-width: .04rem;
        border-color: #b2d5f4;
        background: $blue;
      }
    }
    .desc{
      line-height: calc(24 / 14 * 1em);
      margin-top: .1rem;
      .t2{
        img{
          width: .14rem; margin-right: .03rem;
        }
        span{
          flex: 1;
        }
      }
    }
    .text{
      width: 2.08rem;
    }
    .imgs{
      .img{
        margin-right: .2rem; margin-bottom: .2rem;
        width: 1rem; height: .75rem;
        object-fit: cover;
      }
    }
    .btn-camera{
      width: 1rem;
      height: 0.75rem;
      border: dashed 1px #cae1ec;
      position: relative;
      .icon{
        width: .28rem;
      }
      input{
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
</style>